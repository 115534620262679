import * as qs from 'qs'
import type { UseFetchOptions } from 'nuxt/app'

/**
 * Products can be: vouchers, coupons, gift-cards or events
 * In the future will be good to create more files than just the index.ts
 * and import them here
 * */

export const useProduct = (id: string): any => {
  // const hasAccessToWindowObject = useHasAccessToWindowObject()
  //
  // /** A single event, loaded from permalink */
  // const event = sharedRef(null, `useProduct-event-${id}`)
  //
  // /** The event's availability */
  // const availability = sharedRef(null, `useProduct-availability-${id}`)
  //
  // /** Monthly events */
  // const availableDates = sharedRef(null, `useProduct-availableDates-${id}`)
  //
  // /** Top events */
  // const topEvents = sharedRef(null, `useProduct-topEvents-${id}`)
  // const loadingTopEvents = sharedRef(false, `useProduct-loadingTopEvents-${id}`)
  //
  // /** Recommended events */
  // const recommendedEvents = sharedRef(null, `useProduct-recommendedEvents-${id}`)
  // const loadingRecommendedEvents = sharedRef(false, `useProduct-loadingRecommendedEvents-${id}`)
  //
  // /** New Events */
  // const newEvents = sharedRef(null, `useProduct-newEvents-${id}`)
  // const loadingNewEvents = sharedRef(false, `useProduct-loadingNewEvents-${id}`)
  //
  // /** Events page */
  // const events = sharedRef(null, `useProduct-events-${id}`)
  // const loading = sharedRef(false, `useProduct-loading-${id}`)
  //
  // /** Error */
  // const error = sharedRef(null, `useCustom-error-${id}`)
  //
  // /** Events from a given supplier */
  // const supplierEvents = sharedRef(null, `useProduct-supplierEvents-${id}`)
  // const loadingSupplierEvents = sharedRef(false, `useProduct-loadingSupplierEvents-${id}`)
  //
  // /** Similar events */
  // const similarEvents = sharedRef(null, `useProduct-similarEvents-${id}`)
  // const loadingSimilarEvents = sharedRef(false, `useProduct-loadingSimilarEvents-${id}`)
  //
  // /** Similar online events */
  // const similarOnlineEvents = sharedRef(null, `useProduct-similarOnlineEvents-${id}`)
  // const loadingSimilarOnlineEvents = sharedRef(false, `useProduct-loadingSimilarOnlineEvents-${id}`)
  //
  // /** Wrapper for API requests */
  // const { makeRequest } = useApiHandler(hasAccessToWindowObject)
  //
  // /**
  //  * METHODS
  //  * */
  // const cleanEvents = () => {
  //   events.value = null
  // }
  //
  // const loadEvents = async (params) => {
  //   // Logger.debug(`useProduct/${id}/loadEvents`, params)
  //   try {
  //     loading.value = true
  //     events.value = await makeRequest('getEvents', params)
  //     error.value = nulls
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/loadEvents`, err)
  //   } finally {
  //     loading.value = false
  //   }
  // }
  //
  // // Method to call an API endpoint and update `events`, `loading` and `error` properties
  // /** @deprecated this function was changed into the sold out events */
  // const getEventAvailableDates = async (id) => {
  //   // Logger.debug(`useProduct/${id}/getEventAvailableDates`, id)
  //   try {
  //     loading.value = true
  //     availableDates.value = await makeRequest('getEventAvailableDates', id)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/getEventAvailableDates`, err)
  //   } finally {
  //     loading.value = false
  //   }
  // }
  //
  // // Method to call an API endpoint and update `events`, `loading` and `error` properties
  // const getAvailabilityByEventIds = async (ids) => {
  //   // Logger.debug(`useProduct/${id}/getAvailabilityByEventIds`, ids)
  //
  //   try {
  //     loading.value = true
  //
  //     availability.value = await makeRequest('getAvailabilityByEventIds', ids)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/getAvailabilityByEventIds`, err)
  //   } finally {
  //     loading.value = false
  //   }
  // }
  // const requestDates = async (params) => {
  //   // Logger.debug(`useProduct/${id}/requestDates`, params)
  //
  //   try {
  //     loading.value = true
  //     await makeRequest('requestDates', params)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/requestDates`, err)
  //   } finally {
  //     loading.value = false
  //   }
  // }
  //
  // const getEventPreviewByPermalink = async (permalink: string) => {
  //   // Logger.debug(`useProduct/${id}/getEventPreviewByPermalink`, id)
  //
  //   try {
  //     loading.value = true
  //     event.value = await makeRequest('getEventPreviewByPermalink', permalink)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/getEventPreviewByPermalink`, err)
  //   } finally {
  //     loading.value = false
  //   }
  // }
  //
  // const getEventById = async (eventId: string) => {
  //   // Logger.debug(`useProduct/${id}/getEventById`, id)
  //
  //   try {
  //     loading.value = true
  //     event.value = await makeRequest('getEventById', eventId)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/getEventById`, err)
  //   } finally {
  //     loading.value = false
  //   }
  // }
  //
  // const loadEventsByIds = async (ids: string[] = []) => {
  //   // Logger.debug(`useProduct/${id}/loadEventsByIds`)
  //   if (ids.length < 1) return []
  //   let eventResults = []
  //   try {
  //     const params = `?search=id:${ids.join(',')}&include=supplier.logo,address.locality,reviews`
  //
  //     eventResults = await makeRequest('getEvents', params)
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/loadEventsByIds`, err)
  //   }
  //   return eventResults
  // }
  //
  // const loadTopEvents = async () => {
  //   // Logger.debug(`useProduct/${id}/loadTopEvents`)
  //
  //   try {
  //     const params = '?orderBy=order_items_sum_quantity&sortedBy=desc&limit=10'
  //
  //     loadingTopEvents.value = true
  //     topEvents.value = await makeRequest('getEvents', params)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/loadTopEvents`, err)
  //   } finally {
  //     loadingTopEvents.value = false
  //   }
  // }
  //
  // const loadRecommendedEvents = async () => {
  //   // Logger.debug(`useProduct/${id}/loadRecommendedEvents`)
  //
  //   try {
  //     const params = '?limit=10&search=recommended:1'
  //
  //     loadingRecommendedEvents.value = true
  //     recommendedEvents.value = await makeRequest('getEvents', params)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/loadRecommendedEvents`, err)
  //   } finally {
  //     loadingRecommendedEvents.value = false
  //   }
  // }
  //
  // const loadNewEvents = async () => {
  //   // Logger.debug(`useProduct/${id}/loadNewEvents`)
  //
  //   try {
  //     const params = '?orderBy=created_at&sortedBy=desc&limit=10'
  //
  //     loadingNewEvents.value = true
  //     newEvents.value = await makeRequest('getEvents', params)
  //     error.value = null
  //   } catch (err) {
  //     error.value = err?.response?.data || err
  //     // Logger.error(`useProduct/${id}/loadNewEvents`, err)
  //   } finally {
  //     loadingNewEvents.value = false
  //   }
  // }
  //
  //
  //
  // return {
  //   /* Single event object */
  //   event: computed(() => paginationGetters.validatePagination(event.value)),
  //
  //   /* Supplier events */
  //   supplierEvents: computed(() => paginationGetters.validatePagination(supplierEvents.value)),
  //   loadingSupplierEvents: computed(() => loadingSupplierEvents.value),
  //
  //   /* Top Events */
  //   topEvents: computed(() => paginationGetters.validatePagination(topEvents.value)),
  //   loadingTopEvents: computed(() => loadingTopEvents.value),
  //
  //   /* Recommended Events */
  //   recommendedEvents: computed(() => paginationGetters.validatePagination(recommendedEvents.value)),
  //   loadingRecommendedEvents: computed(() => loadingRecommendedEvents.value),
  //
  //   /* New Events */
  //   newEvents: computed(() => paginationGetters.validatePagination(newEvents.value)),
  //   loadingNewEvents: computed(() => loadingNewEvents.value),
  //
  //   /* Event Pagination */
  //   events: computed(() => paginationGetters.validatePagination(events.value)),
  //   loading: computed(() => loading.value),
  //
  //   /* Similar Events */
  //   similarEvents: computed(() => paginationGetters.validatePagination(similarEvents.value)),
  //   loadingSimilarEvents: computed(() => loadingSimilarEvents.value),
  //
  //   /* Similar Online Events */
  //   similarOnlineEvents: computed(() => paginationGetters.validatePagination(similarOnlineEvents.value)),
  //   loadingSimilarOnlineEvents: computed(() => loadingSimilarOnlineEvents.value),
  //
  //   error: computed(() => error.value),
  //   availableDates: computed(() => paginationGetters.validatePagination(availableDates.value)),
  //   availability: computed(() => availability.value),
  /* Methods */
  // cleanEvents,
  // loadEvents,
  // loadTopEvents,
  // loadNewEvents,
  // loadRecommendedEvents,
  // getEventPreviewByPermalink,
  // getEventAvailableDates,
  // getAvailabilityByEventIds,
  // loadSimilarEvents,
  // loadSimilarOnlineEvents,
  // getEventById,
  // loadEventsByIds,
  // requestDates,
  // }
}

export const useSubscribeEmailToEventUpdates = () => {
  /** Wrapper for API requests */
  const error = ref(null)
  const subscribeEmailToEventUpdates = async ({
    permalink,
    email,
  }: {
    permalink: string
    email: string
  }) => {
    // Logger.debug(`useSubscribeEmailToEventUpdates/${id}/subscribeEmailToEventUpdates`, id)

    try {
      loading.value = true
      await $fetch(`v1/store/events/${permalink}/subscribe`, {
        method: 'POST',
        body: { email },
      })

      error.value = null
    } catch (err) {
      error.value = err?.response?.data || err
      // Logger.error(`useSubscribeEmailToEventUpdates/${id}/subscribeEmailToEventUpdates`, err)
    } finally {
      loading.value = false
    }
  }

  return {
    subscribeEmailToEventUpdates,
    error,
  }
}

export const useFetchEventByPermalink = <T>(
  permalink: string,
  options?: UseFetchOptions<T>
) =>
  useKftApiFetch<T>(
    `v1/store/events/${permalink}?${qs.stringify(
      {
        include: ['address.locality', 'supplier', 'reviewsNew'],
      },
      { arrayFormat: 'comma' }
    )}`,
    options
  )

export const useFetchSupplierEvents = <T>(
  supplierId: string,
  embeddedPublished?: boolean,
  orderByProperty?: string,
  options?: UseFetchOptions<T>
) => {
  const paramsObj = {
    limit: '50',
    include: ['supplier', 'categories'],
    ...(orderByProperty && { orderBy: orderByProperty }),
    search: `supplier_id:${supplierId};hasEvent:false${embeddedPublished ? ';embeddedPublished:true' : ''}`,
  }

  const url = `v1/store/events?${qs.stringify(paramsObj, {
    // encodeValuesOnly: true, // prettify URL
    encode: false,
    arrayFormat: 'comma',
  })}`
  return useKftApiFetch(url, options)
}

export const useFetchSimilarEvents = <T>(
  partnerPermalink: string,
  options?: UseFetchOptions<T>
) => {
  const paramsObj = {
    orderBy: 'distance',
    sortedBy: 'asc',
    search: 'hasEvent:true',
  }

  const url = `v1/store/events/${partnerPermalink}/similar?${qs.stringify(
    paramsObj,
    {
      encode: false,
      arrayFormat: 'comma',
    }
  )}`
  return useKftApiFetch(url, options)
}

export const useFetchSimilarOnlineEvents = <T>(
  category: string,
  options?: UseFetchOptions<T>
) => {
  const paramsObj = {
    limit: '10',
    // search: { hasEvent: true, type: 'ONLINE', category },
    search: `hasEvent:true;type:ONLINE;category:${category}`,
  }

  const url = `v1/store/events?${qs.stringify(paramsObj, {
    encode: false,
    arrayFormat: 'comma',
  })}`
  return useKftApiFetch(url, options)
}
